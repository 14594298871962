import { XIconProps, XIconName } from "./types";

import { XArrowBack } from "./x-arrow-back";
import { XArrowDown } from "./x-arrow-down";
import { XArrowUp } from "./x-arrow-up";
import { XBank } from "./x-bank";
import { XBusiness } from "./x-business";
import { XBusinessCenter } from "./x-business-center";
import { XCar } from "./x-car";
import { XCategory } from "./x-category";
import { XChartPie } from "./x-chart-pie";
import { XChevronDown } from "./x-chevron-down";
import { XChevronRight } from "./x-chevron-right";
import { XCircle } from "./x-circle";
import { XClose } from "./x-close";
import { XCreditCard } from "./x-credit-card";
import { XEmail } from "./x-email";
import { XEye } from "./x-eye";
import { XEyeOff } from "./x-eye-off";
import { XFilterVariant } from "./x-filter-variant";
import { XHome } from "./x-home";
import { XInformationOutline } from "./x-information-outline";
import { XLaunch } from "./x-launch";
import { XLock } from "./x-lock";
import { XPhone } from "./x-phone";
import { XPlus } from "./x-plus";
import { XPolicy } from "./x-policy";
import { XRequestQuote } from "./x-request-quote";
import { XSchool } from "./x-school";
import { XStore } from "./x-store";
import { XStyle } from "./x-style";
import { XTrendingUp } from "./x-trending-up";
import { XTune } from "./x-tune";

export const mapIconNameToIconComponent: Record<
  XIconName,
  React.ComponentType<XIconProps>
> = {
  "arrow-back": XArrowBack,
  "arrow-down": XArrowDown,
  "arrow-up": XArrowUp,
  bank: XBank,
  business: XBusiness,
  "business-center": XBusinessCenter,
  car: XCar,
  category: XCategory,
  "chart-pie": XChartPie,
  "chevron-down": XChevronDown,
  "chevron-right": XChevronRight,
  circle: XCircle,
  close: XClose,
  "credit-card": XCreditCard,
  email: XEmail,
  eye: XEye,
  "eye-off": XEyeOff,
  "filter-variant": XFilterVariant,
  home: XHome,
  "information-outline": XInformationOutline,
  launch: XLaunch,
  lock: XLock,
  phone: XPhone,
  plus: XPlus,
  policy: XPolicy,
  "request-quote": XRequestQuote,
  school: XSchool,
  store: XStore,
  style: XStyle,
  "trending-up": XTrendingUp,
  tune: XTune,
};

/**
 * Converts an icon name to the corresponding react-native-paper icon string.
 * Needed for a few specific components that only take in icon names as strings.
 *
 * @param icon The icon name to convert.
 * @returns The corresponding react-native-paper icon string.
 */
export function getNativeIconString(icon: XIconName) {
  switch (icon) {
    case "arrow-back":
      return "arrow-left";
    case "business":
      return "briefcase-variant";
    case "business-center":
      return "briefcase-variant";
    case "category":
      return "shape";
    case "circle":
      return "checkbox-blank-circle";
    case "credit-card":
      return "credit-card-outline";
    case "eye-off":
      return "eye-off-outline";
    case "policy":
      return "shield-search";
    case "style":
      return "palette-swatch";
  }
  return icon;
}
