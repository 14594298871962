import { LockRounded } from "@mui/icons-material";
import { DEFAULT_ICON_SIZE, XIconProps } from "./types";
import { mapIconColorToPalette } from "shared/theme/utils";
import { greyIconsColor } from "shared/theme";

export function XLock({
  color,
  size = DEFAULT_ICON_SIZE,
  style,
  onClick,
}: XIconProps) {
  let mappedColor = greyIconsColor;
  if (color != null) {
    mappedColor = mapIconColorToPalette[color] ?? color;
  }
  return (
    <LockRounded
      onClick={onClick}
      sx={{
        color: mappedColor,
        height: size,
        width: size,
        ...style,
      }}
    />
  );
}
