import { HTMLInputTypeAttribute } from "react";
import { InputModeOptions, KeyboardTypeOptions } from "react-native";

export const mapKeyboardTypeToInputMode: Record<
  KeyboardTypeOptions,
  InputModeOptions
> = {
  "ascii-capable": "text",
  "decimal-pad": "decimal",
  default: "text",
  "email-address": "email",
  "name-phone-pad": "text",
  "number-pad": "numeric",
  "numbers-and-punctuation": "text",
  numeric: "numeric",
  "phone-pad": "tel",
  twitter: "text",
  url: "url",
  "visible-password": "text",
  "web-search": "search",
};

export function isSecureTextEntryType(
  type: HTMLInputTypeAttribute | undefined
) {
  return type === "password";
}
