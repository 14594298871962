import { BusinessCenter } from "@mui/icons-material";
import { DEFAULT_ICON_SIZE, XIconProps } from "./types";
import { greyIconsColor } from "shared/theme";
import { mapIconColorToPalette } from "shared/theme/utils";

export function XBusinessCenter({
  color,
  size = DEFAULT_ICON_SIZE,
  style,
  onClick,
}: XIconProps) {
  let mappedColor = greyIconsColor;
  if (color != null) {
    mappedColor = mapIconColorToPalette[color] ?? color;
  }
  return (
    <BusinessCenter
      onClick={onClick}
      style={{
        color: mappedColor,
        height: size,
        width: size,
        ...style,
      }}
    />
  );
}
